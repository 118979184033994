// eslint-disable-next-line no-restricted-imports
import OriginalImage, {
  StaticImageData,
  ImageProps as OriginalImageProps,
} from 'next/image';
import { useState } from 'react';
import noImage from '@/assets/images/no-image-square.png';

type ImageProps = {
  src?: string | StaticImageData | null;
  noImageSrc?: string | StaticImageData | null;
} & Omit<OriginalImageProps, 'src'>;

const UNOPTIMIZED_IMAGE_HOSTS = [
  'imagedelivery.net',
];

const UNOPTIMIZED_REG = new RegExp(`https?://(${UNOPTIMIZED_IMAGE_HOSTS.join('|')})`);

export const Image = ({
  src,
  noImageSrc,
  ...rest
}: ImageProps) => {
  const [nextSrc, setNextSrc] = useState(src || noImageSrc || noImage);
  let unoptimized = false;

  if (typeof nextSrc === 'string') {
    unoptimized = !!nextSrc.match(UNOPTIMIZED_REG);
  }

  return (
    <>
      <OriginalImage
        src={nextSrc}
        onError={() => setNextSrc(noImageSrc || noImage)}
        unoptimized={unoptimized}
        {...rest}
      />
    </>
  );
};
