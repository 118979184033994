import {
  Box,
  BoxProps
} from '@chakra-ui/react';
import React from 'react';

type PcDisplayBlockProps = {
  children: React.ReactNode;
} & BoxProps;

/**
 * PCのみ表示するコンポーネント
 * @param children
 * @param rest
 * @constructor
 */
export const PcDisplayBlock = ({ children, ...rest }: PcDisplayBlockProps) => {
  return (
    <Box
      display={{ base: 'none', lg: 'block' }}
      {...rest}
    >
      {children}
    </Box>
  );
};
