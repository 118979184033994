export const graphQLErrorMessages = {
  notAuthenticated: 'Not Authorized',
} as const;

export type GraphQLErrorMessage = typeof graphQLErrorMessages[keyof typeof graphQLErrorMessages];

export type GraphQLErrorResponseError = {
  errors: { message: GraphQLErrorMessage }[];
}

export const isGraphQLErrorResponseError = (data: any): data is GraphQLErrorResponseError => {
  return data.errors !== undefined;
};

export class GraphQLError extends Error {
}

export const findGraphQLValidationError = (errors: any[]): ErrorsValidationErrorField | null => {
  const error = errors.find((error) => error.extensions && error.extensions.code === 'VALIDATION_ERROR');

  return error ? error : null;
};

export type ErrorsValidationErrorField = {
  message: string;
  locations: { line: number, column: number }[];
  path: string[];
  extensions: ValidationError;
}

export type ValidationError = {
  code: 'VALIDATION_ERROR';
  errors: { attributeName: string, humanAttributeName: string, messages: string[] }[];
  fullMessages: string[];
}

export class GraphQLValidationError extends GraphQLError {
  validationError: ValidationError;

  constructor(validationError: ValidationError) {
    super();
    this.validationError = validationError;
  }
}

