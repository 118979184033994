import {
  Box,
  Spinner
} from '@chakra-ui/react';
import React from 'react';
import { useRequireLoginCustomer } from '@/hooks/useRequireLoginCustomer';

type RequiredCustomerLoginProps = {
  isRequired?: boolean;
  children: React.ReactNode;
}

export const RequiredCustomerLogin = ({
  isRequired = true,
  children
}: RequiredCustomerLoginProps) => {
  const { isCheckedLogin, isCustomerLogin } = useRequireLoginCustomer({ isRequired });

  if (!isRequired) {
    return (
      <>{children}</>
    );
  }

  return (
    <>
      {(isCheckedLogin && isCustomerLogin) && (
        <>
          {children}
        </>
      )}

      {(!isCheckedLogin) && (
        <Box
          textAlign={'center'}
          py={8}
        >
          <Spinner
            color={'brandSecondary.500'}
            size={{ base: 'md', lg: 'lg' }}
            speed={'0.7s'}
          />
        </Box>
      )}
    </>
  );
};
