import {
  Box,
  BoxProps,
} from '@chakra-ui/react';
import React, {
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import {
  TextLink,
  TextLinkProps
} from '@/components/Link';

interface MoreReadTextProps extends BoxProps {
  children: React.ReactNode;
  textLinkProps?: Omit<TextLinkProps, 'children'>;
  webkitLineClamp?: number;
}

export const ReadMoreText = ({
  children,
  textLinkProps,
  webkitLineClamp = 3,
  ...boxProps
}: MoreReadTextProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isMoreText, setMoreText] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleClick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const checkIsMoreText = useCallback(() => {
    if (isOpen) {
      setMoreText(true);
    } else if (ref.current) {
      const isMore = ref.current.scrollHeight > ref.current.offsetHeight;
      setMoreText(isMore);
    }
  }, [isOpen]);

  useEffect(() => {
    const controller = new AbortController();
    checkIsMoreText();
    window.addEventListener('resize', checkIsMoreText, { signal: controller.signal });
    return () => {
      controller.abort();
    };
  }, [checkIsMoreText, ref]);

  return (
    <>
      <Box
        css={{
          display: isOpen ? 'block' : '-webkit-box',
          overflow: 'hidden',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: webkitLineClamp,
        }}
        ref={ref}
        {...boxProps}
      >
        {children}
      </Box>
      {isMoreText && (
        <TextLink
          w={'100%'}
          display={'inline-block'}
          textAlign={'right'}
          fontWeight={'normal'}
          onClick={handleClick}
          {...textLinkProps}
        >
          {isOpen ? '閉じる' : 'もっと見る'}
        </TextLink>
      )}
    </>
  );
};
