import {
  Box,
  BoxProps,
  Text
} from '@chakra-ui/react';

interface HeadingTitleProps extends BoxProps {
  title: string;
  subTitle?: string;
  color?: string;
  fontWeight?: string;
}

export const HeadingTitle = ({
  title,
  subTitle,
  color = 'brand.500',
  fontWeight = 'extrabold',
  ...props
}: HeadingTitleProps) => {
  return (
    <Box
      pb={{ base: 4, lg: 6 }}
      {...props}
    >
      <Text
        textStyle={'h1'}
        fontWeight={fontWeight}
        color={color}
        lineHeight={'132.5%'}
        letterSpacing={'0.13em'}
        whiteSpace={'pre-line'}
      >
        {title}
      </Text>
      {subTitle && (
        <Text
          textStyle={'h6'}
          fontWeight={'bold'}
          color={color}
          letterSpacing={'3px'}
          pt={'8px'}
        >
          {subTitle}
        </Text>
      )}
    </Box>
  );
};
