import {
  Box,
  HStack
} from '@chakra-ui/react';
import { Image } from '@/components/Image';

export type SnsLinksProps = {};

export type snsLinks = {
  snsHref: string;
  snsSrc: string;
  snsAlt: string;
}

const snsLinks = [
  {
    snsHref: 'https://www.youtube.com/channel/UCfGSFbFuxxrWfJxZ_jA5tzQ',
    snsSrc: '/images/sns_icon/youtube_icon.png',
    snsAlt: 'youtube'
  },
  {
    snsHref: 'https://twitter.com/reraku_official',
    snsSrc: '/images/sns_icon/twitter_icon.png',
    snsAlt: 'twitter'
  },
  {
    snsHref: 'https://www.instagram.com/rerakugirl_jp/',
    snsSrc: '/images/sns_icon/instagram_icon.png',
    snsAlt: 'instagram'
  },
];

export const SnsLinks = ({ }: SnsLinksProps) => {
  return (
    <HStack
      justifyContent={'center'}
    >
      {snsLinks.map(({
        snsHref,
        snsSrc,
        snsAlt
      }: snsLinks, index) =>
        <Box
          as={'a'}
          href={snsHref}
          target={'_blank'}
          key={index}
          cursor={'pointer'}
        >
          <Image
            src={snsSrc}
            alt={snsAlt}
            width={40}
            height={40}
          />
        </Box>
      )}
    </HStack>
  );
};
