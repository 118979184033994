export type Size = 'large' | 'medium' | 'small' | 'mini';

export const sizes: { [key in Size]: { height: string, fontSize: string } } = {
  large: {
    height: '48px',
    fontSize: '18px',
  },
  medium: {
    height: '28px',
    fontSize: '16px',
  },
  small: {
    height: '24px',
    fontSize: '14px',
  },
  mini: {
    height: '20px',
    fontSize: '12px',
  },
};
