import {
  Box,
  BoxProps,
  useBreakpointValue
} from '@chakra-ui/react';
import { Image } from '@/components/Image';

export type AppStoreLinkProps = {} & BoxProps;

export const AppStoreLink = ({ ...boxProps }: AppStoreLinkProps) => {
  const isPc = useBreakpointValue({ sm: 'false', md: 'true', lg: 'true' });
  const pcW = 180;
  const spW = 160;
  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      {...boxProps}
    >
      <Box
        as={'a'}
        m={2}
        href={'https://apps.apple.com/jp/app/re-ra-ku-group/id1604692153'}
      >
        <Image
          src={'/images/app_badge/ios-app-badge.png'}
          alt={'iOS App Storeからダウンロード'}
          width={isPc ? pcW : spW}
          height={isPc ? pcW / (256 / 95) : spW / (256 / 95)}
        />
      </Box>
      <Box
        as={'a'}
        m={2}
        href={'https://play.google.com/store/apps/details?id=jp.epark.salonappx0e0o1&hl=ja&gl=US&pli=1s'}
      >
        <Image
          src={'/images/app_badge/google-play-badge.png'}
          alt={'Google Playからダウンロード'}
          width={isPc ? pcW : spW}
          height={isPc ? pcW / (256 / 77) : spW / (256 / 77)}
        />
      </Box>
    </Box>
  );
};
