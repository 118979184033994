import {
  Box,
  BoxProps,
  Stack,
  Text
} from '@chakra-ui/react';
import { memo } from 'react';

export interface ContentWrapperProps extends BoxProps {
  text?: string | string[];
}

export const ContentWrapper = memo((
  {
    text,
    children,
    ...props
  }: ContentWrapperProps
) => {
  let texts: string[] = [];
  if (text) {
    texts = Array.isArray(text) ? text : [text];
  }
  return (
    <Box
      w={'full'}
      bg={'white'}
      p={text ? 5 : undefined}
      {...props}
    >
      <Stack
        direction={{ base: 'column', lg: 'row' }}
      >
        {texts && texts.map((t, index) => (
          <Text
            key={index}
          >{t}</Text>
        ))}
      </Stack>
      {children}
    </Box>
  );
});
ContentWrapper.displayName = 'ContentWrapper';
