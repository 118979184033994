import {
  atom,
  selector,
  useRecoilCallback,
  useRecoilValue
} from 'recoil';
import {
  RecoilAtomKeys,
  RecoilSelectorKeys
} from '@/config/recoileKeys';
import { syncSessionStorageEffect } from '@/lib/recoil/syncSessionStorage';

const utmTrackingParameterKeys = [
  'utm_id',
  'utm_source',
  'utm_medium',
  'utm_campaign',
] as const;

type UtmTrackingParameterKey = typeof utmTrackingParameterKeys[number];

export type UTMTrackingParameter = {
  [Property in UtmTrackingParameterKey]?: string;
};

const trackingParameterKeys = [
  ...utmTrackingParameterKeys,
] as const;

type TrackingParameterKey = typeof trackingParameterKeys[number];

export type TrackingParameter = {
  [Property in TrackingParameterKey]?: string;
};

export const trackingParameterState = atom<TrackingParameter>({
  key: RecoilAtomKeys.TRACKING_PARAMETER,
  default: {},
  effects: [
    syncSessionStorageEffect(RecoilAtomKeys.TRACKING_PARAMETER)
  ]
});

// UTMのパラメータのみを取得
const utmTrackingParameterSelector = selector({
  key: RecoilSelectorKeys.UTM_TRACKING_PARAMETER,
  get: ({ get }): UTMTrackingParameter => {
    const parameters = get(trackingParameterState);

    return Object.fromEntries(
      utmTrackingParameterKeys.map(key => {
        if (key in parameters && parameters[key]) {
          return [key, parameters[key]];
        }
      }).filter(p => !!p) as [UtmTrackingParameterKey, string][]
    );
  }
});

export const recoilTrackingParameterSelectors = {
  useUtmTrackingParameterSelector: () => useRecoilValue(utmTrackingParameterSelector),
};

export const recoilTrackingParameterActions = {
  useSetParameter: () => (
    useRecoilCallback(({ set }) => (parameters: Record<string, any>) => {
      const nextParameters = Object.fromEntries(
        Object.keys(parameters).map(key => {
          if (trackingParameterKeys.includes(key as TrackingParameterKey)) {
            return [key, String(parameters[key])];
          }
        }).filter(p => !!p) as [string, string][]
      );
      if (Object.keys(nextParameters).length > 0) {
        set(trackingParameterState, nextParameters);
      }
    })
  ),

  useResetParameter: () => (
    useRecoilCallback(({ set }) => () => {
      set(trackingParameterState, {});
    }, [])
  )
};
