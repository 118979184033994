
import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Breadcrumb as ChakraBreadcrumb,
  BreadcrumbProps as ChakraBreadcrumbProps,
  BreadcrumbItem,
  Text
} from '@chakra-ui/react';
import Link from 'next/link';
import { ReturnPageInfo } from '@/config/pages';


type BreadcrumbProps = {
  pageLinks: ReturnPageInfo[]
  isShowBreadcrumb: boolean;
} & ChakraBreadcrumbProps;

export const Breadcrumb = ({
  pageLinks,
  isShowBreadcrumb = true,
}: BreadcrumbProps) => {
  const lastIndex = pageLinks.length - 1;


  return (
    <>
      {pageLinks && (
        <Box
          display={{ base: isShowBreadcrumb ? 'block' : 'none' }}
          bgColor={{ base: 'white', lg: 'gray.200' }}
        >
          <ChakraBreadcrumb
            textStyle={'h6'}
            py={{ base: 2, md: 4 }}
            px={{ base: 2, md: 4 }}
            spacing={'3px'}
            whiteSpace={'nowrap'}
            overflowX={'auto'}
            css={{
              WebkitOverflowScrolling: 'touch',
              scrollbarWidth: 'none',
              msOverflowStyle: 'none',
              '&::-webkit-scrollbar': {
                display: 'none'
              }
            }}
            color={'brand.500'}
            separator={
              <ChevronRightIcon
                color={'gray.500'}
              />
            }
          >
            {pageLinks.map((pageLink, index) => (
              <BreadcrumbItem
                key={pageLink.href}
              >
                {index !== lastIndex ? (
                  <Link href={pageLink.href}>{pageLink.name}</Link>
                ) : (
                  <Text
                    color={'brandSecondary.500'}
                  >
                    {pageLink.name}
                  </Text>
                )}
              </BreadcrumbItem>
            ))}
          </ChakraBreadcrumb>
        </Box>
      )}
    </>
  );
};
