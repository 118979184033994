export const deepSet = (object: Record<string, any>, keys: string[], value: any) => {
  let nextObject = {};

  Object.keys(object).forEach((objectKey) => {
    if (!Array.isArray(object[objectKey]) && object[objectKey] !== null && typeof object[objectKey] === 'object') {
      nextObject = {
        ...nextObject,
        [objectKey]: deepSet(object[objectKey], keys, value)
      };
    } else if (keys.includes(objectKey)) {
      nextObject = {
        ...nextObject,
        [objectKey]: value
      };
    } else {
      nextObject = {
        ...nextObject,
        [objectKey]: object[objectKey]
      };
    }
  });
  return nextObject;
};
