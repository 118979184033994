import {
  atom,
  useRecoilCallback,
  useRecoilValue,
} from 'recoil';
import { z } from 'zod';
import { RecoilAtomKeys } from '@/config/recoileKeys';
import { PmCustomerStatusEnum } from '@/graphql/generated/graphql';
import { syncSessionStorageEffect } from '@/lib/recoil/syncSessionStorage';
import {
  authenticationTokenInput,
  emailInput,
  nameKanaInput,
  passwordInput,
  sexInput,
} from '@/lib/zod/schema/formInputs';
import {
  PasswordSettingFormSchema,
} from '@/stores/forgotPassword';
import { dayjs } from '@/utils/dayjs';
import { TEL_REG } from '@/utils/regexp';

export enum Steps {
  emailForm = 'emailForm',
  registered = 'registered',
  signupForm = 'signupForm',
}

/**
 * プロフィール入力フォーム
 */
export const customerSignupFormSchema = z.object({
  nameKana: nameKanaInput.min(1, { message: '入力してください' }),
  sex: sexInput,
  tel: z.string()
    .min(1, { message: '入力してください' })
    .regex(TEL_REG, { message: '半角数字のみで入力してください' })
    .min(10, { message: '10桁以上入力してください' }).max(12, { message: '12桁以下で入力してください' }),
  birthday: z.object({
    year: z.string(),
    month: z.string(),
    day: z.string(),
  }).refine(({ year, month, day }) => {
    const date = `${year}-${month}-${day}`;
    // 空文字または実在しない日付でない場合はfalseを返す
      return dayjs(date, 'YYYY-MM-DD').format('YYYY-MM-DD') === date;
  }, { message: '日付が無効です。' }),
  password: passwordInput,
  passwordConfirm: passwordInput,
  pmShopId: z.string(),
  authenticationToken: authenticationTokenInput,
}).superRefine(({ password, passwordConfirm }, ctx) => {
  if (password !== passwordConfirm) {
    ctx.addIssue({
      path: ['passwordConfirm'],
      code: 'custom',
      message: 'パスワードが一致しません',
    });
  }
});

export type CustomerSignupFormSchema = z.infer<typeof customerSignupFormSchema>;

export const defaultCustomerSignupForm: CustomerSignupFormSchema = {
  nameKana: '',
  sex: 0,
  tel: '',
  birthday: {
    year: '',
    month: '',
    day: '',
  },
  password: '',
  passwordConfirm: '',
  pmShopId: '',
  authenticationToken: '',
};

export type CustomerRegisterState = {
  step: Steps;
  email: string | null;
  pmShopId: string | null;
  pmCustomerStatus: PmCustomerStatusEnum | null;
  passwordSettingForm: PasswordSettingFormSchema | null;
  signupForm: CustomerSignupFormSchema | null;
}

export const defaultCustomerRegister: CustomerRegisterState = {
  step: Steps.emailForm,
  email: null,
  pmShopId: null,
  pmCustomerStatus: null,
  passwordSettingForm: null,
  signupForm: null,
};

const customerRegisterValidationSchema = z.object({
  step: z.nativeEnum(Steps).nullable(),
  email: emailInput.nullable(),
  pmShopId: z.string().nullable(),
  pmCustomerStatus: z.nativeEnum(PmCustomerStatusEnum).nullable(),
  passwordSettingForm: z.object({
    isRequiredEmail: z.boolean(),
    isRequiredTel: z.boolean(),
    email: z.string().nullable(),
    tel: z.string().nullable(),
    authenticationToken: z.string().nullable(),
    password: z.string().nullable(),
    passwordConfirm: z.string().nullable(),
  }).nullable(),
  signupForm: z.object({
    nameKana: z.string().nullable(),
    sex: z.number().nullable(),
    tel: z.string().nullable(),
    birthday: z.object({
      year: z.string().nullable(),
      month: z.string().nullable(),
      day: z.string().nullable(),
    }).nullable(),
    password: z.string().nullable(),
    passwordConfirm: z.string().nullable(),
    pmShopId: z.string().nullable(),
    authenticationToken: z.string().nullable(),
  }).nullable(),
});

export const customerRegisterState = atom<CustomerRegisterState>({
  key: RecoilAtomKeys.CUSTOMER_REGISTER,
  default: defaultCustomerRegister,
  effects: [
    syncSessionStorageEffect(
      RecoilAtomKeys.CUSTOMER_REGISTER,
      customerRegisterValidationSchema
    ),
  ]
});

export const recoilCustomerRegisterSelectors = {
  useState: () => {
    return useRecoilValue(customerRegisterState);
  },
};

export const recoilCustomerRegisterActions = {
  useSetPmShopId: () => (
    useRecoilCallback(({ set }) => (
      pmShopId: string,
    ) => {
      set(customerRegisterState, (prev) => ({
        ...prev,
        pmShopId
      }));
    }, [])
  ),
  useReset: () => (
    useRecoilCallback(({ set }) => () => {
      set(customerRegisterState, () => defaultCustomerRegister);
    }, [])
  ),
  useMoveRegistered: () => (
    useRecoilCallback(({ set }) => ({
      passwordSettingForm,
      pmCustomerStatus,
    }: {
      passwordSettingForm: Partial<CustomerRegisterState['passwordSettingForm']>,
      pmCustomerStatus: PmCustomerStatusEnum,
    }) => {
      set(customerRegisterState, (prev) => ({
        ...prev,
        passwordSettingForm: {
          ...(prev.passwordSettingForm ?? {
            isRequiredEmail: false,
            isRequiredTel: false,
            email: '',
            tel: '',
            password: '',
            passwordConfirm: '',
            authenticationToken: '',
          }),
          ...passwordSettingForm,
        },
        pmCustomerStatus,
        step: Steps.registered,
      }));
    }, [])
  ),
  useMoveSignupForm: () => (
    useRecoilCallback(({ set }) => ({
      signupForm,
      email,
      pmCustomerStatus,
    }: {
      signupForm: Partial<CustomerRegisterState['signupForm']>,
      email: string,
      pmCustomerStatus: PmCustomerStatusEnum,
    }) => {
      set(customerRegisterState, (prev) => ({
        ...prev,
        signupForm: {
          ...(prev.signupForm ?? defaultCustomerSignupForm),
          ...signupForm,
        },
        pmCustomerStatus,
        step: Steps.signupForm,
        email,
      }));
    }, [])
  )
};
