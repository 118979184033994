import {
  AxiosError,
  AxiosResponse
} from 'axios';
import {
  InternalServerError,
  UnauthenticatedError,
  UnprocessableEntityError
} from '@/errors/httpErrors';

export type ApiResponseErrorData = {
  message: string;
  backtrace: string[];
  status: number;
}

export const withHttpErrorFromBackend = async <T = any>(fn: () => Promise<AxiosResponse<T>>): Promise<AxiosResponse<T>> => {
  try {
    return await fn();
  } catch (e) {
    if (e instanceof AxiosError) {
      const response = e.response;
      if (response) {
        const data = response.data as ApiResponseErrorData;
        const message = data.message || undefined;
        switch (response.status) {
          case 401:
            throw new UnauthenticatedError(message);
          case 422:
            // CSRF Tokenエラー時
            throw new UnprocessableEntityError(message);
          default:
            // Internal Server Error
            throw new InternalServerError(message);
        }
      }
    }
    if (e instanceof Error) {
      throw new InternalServerError(e.message || undefined);
    }
    throw new InternalServerError('原因不明のエラーが発生しました');
  }
};
