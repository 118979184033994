import {
  // eslint-disable-next-line no-restricted-imports
  FormLabel as ChakraFormLabel,
  FormLabelProps as ChakraFormLabelProps
} from '@chakra-ui/react';

interface FormLabelProps extends ChakraFormLabelProps {

}

export const FormLabel = ({ ...props }: FormLabelProps) => {
  return (
    <ChakraFormLabel
      fontWeight={400}
      textStyle={'h6'}
      color={'brandSecondary.500'}
      {...props}
    />
  );
};
