import { Flex } from '@chakra-ui/react';
import router from 'next/router';
import { memo } from 'react';
import { SubmitButton } from '../../Button';
import { PcNavigationMenuItem } from '@/components/Navbar/Pc/PcNavigationMenuItem';
import {
  getPageInfo,
  PageName
} from '@/config/pages';
import { recoilCurrentCustomerInfoSelectors } from '@/stores/currentCustomerInfo';

type PcNavigationMenuProps = {}

const menus: PageName[] = [
  'studio',
  'guide',
  'brand',
  'about_rerakupay',
  'news',
  'column',
  'faq',
  'recruit'
];

export const PcNavigationMenu = memo(({}: PcNavigationMenuProps) => {
  const isCustomerLogin = recoilCurrentCustomerInfoSelectors.useIsCustomerLogin();

  return (
    <Flex
      display={{ base: 'none', lg: 'flex' }}
      flex={5}
      ml={-2}
      px={5}
      justify={'flex-end'}
      fontWeight={'bold'}
      as={'div'}
    >
      {menus.map((menu) => (
        <PcNavigationMenuItem
          key={menu}
          pageLink={getPageInfo(menu, {})}
        />
      ))}
      {isCustomerLogin ? (
        <>
          <SubmitButton
            mx={'1%'}
            m={'11px'}
            size={'sm'}
            _hover={{ textDecoration: 'none' }}
            onClick={async () => await router.push(getPageInfo('mypage', {}).href)}
          >
            マイページ
          </SubmitButton>
        </>
      ) : (
        <SubmitButton
          mx={'1%'}
          m={'11px'}
          size={'sm'}
          _hover={{ textDecoration: 'none' }}
          onClick={async () => await router.push(getPageInfo('login', {}).href)}
        >
          ログイン
        </SubmitButton>
      )}
    </Flex>
  );
});
PcNavigationMenu.displayName = 'PcNavigationMenu';
