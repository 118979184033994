import { AtomEffect } from 'recoil';
import { ZodObject } from 'zod';
import { hasServer } from '@/utils/browser';
import { deepSet } from '@/utils/object';

export const syncSessionStorageEffect = (storageKey: string, zodSchema?: ZodObject<any>): AtomEffect<any> => {
  return ({ setSelf, onSet }) => {
    if (hasServer) return;

    const savedValue = sessionStorage.getItem(storageKey);
    if (savedValue) {
      try {
        const parsedValue = JSON.parse(savedValue);
        zodSchema && zodSchema.parse(parsedValue);
        setSelf(parsedValue);
      } catch (e) {
        console.log(e);
        sessionStorage.removeItem(storageKey);
      }
    }

    onSet((newValue, _, isReset) => {
      if (isReset) {
        sessionStorage.removeItem(storageKey);
      } else {
        try {
          zodSchema && zodSchema.parse(newValue);
          sessionStorage.setItem(
            storageKey,
            JSON.stringify(deepSet(newValue, ['password', 'passwordConfirm'], ''))
          );
        } catch (e) {
          console.log(e);
        }
      }
    });
  };
};
