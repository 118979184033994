import {
  forwardRef,
  Heading,
  HeadingProps
} from '@chakra-ui/react';

interface HeadingTitleProps extends HeadingProps {
}

export const FormHeadingTitle = forwardRef(({ children, ...props }: HeadingTitleProps, ref) => {
  return (
    <Heading
      color={'brandSecondary.500'}
      textAlign={'center'}
      size={{base: 'md', lg: 'lg'}}
      ref={ref}
      {...props}
    >
      {children}
    </Heading>
  );
});
FormHeadingTitle.displayName = 'FormHeadingTitle';
