import {
  Box,
  BoxProps
} from '@chakra-ui/react';
import React from 'react';

type SpDisplayBlockProps = {
  children: React.ReactNode;
} & BoxProps;

/**
 * SPのみ表示するコンポーネント
 * @param children
 * @param rest
 * @constructor
 */
export const SpDisplayBlock = ({ children, ...rest }: SpDisplayBlockProps) => {
  return (
    <Box
      display={{ base: 'block', lg: 'none' }}
      {...rest}
    >
      {children}
    </Box>
  );
};
