import {
  forwardRef,
  // eslint-disable-next-line no-restricted-imports
  Input as ChakraInput,
  InputProps as ChakraInputProps,
} from '@chakra-ui/react';

interface AuthenticationCodeInputProps extends ChakraInputProps {
}

export const AuthenticationCodeInput = forwardRef((
  { ...restProps }: AuthenticationCodeInputProps, ref) => {
  return (
    <ChakraInput
      type={'tel'}
      maxLength={6}
      placeholder={'6桁の数字'}
      borderColor={'gray.300'}
      ref={ref}
      {...restProps}
    />
  );
});
