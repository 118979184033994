import {
  Box,
  BoxProps,
  Icon,
} from '@chakra-ui/react';
import React from 'react';
import { IconType } from 'react-icons';

// もっと良い書き方ああれば変えたい
type Color = BoxProps['bgColor'];
type BreadcrumbsItemProps = {
  height?: number;
  bgColor?: Color;
  fontColor?: Color;
  activeBgColor?: Color;
  activeFontColor?: Color;
  borderColor?: Color;
  isActive?: boolean;
  isLast?: boolean;
  icon?: IconType;
  children: React.ReactNode;
}

const arrowWidth = 26;

const arrowStyle = (height: number) => {
  return {
    content: '""',
    position: 'absolute',
    top: -2,
    right: `-${arrowWidth - 3}px`,
    borderTop: `${Math.ceil(height / 2) + 8}px solid transparent`,
    borderBottom: `${Math.ceil(height / 2) + 10}px solid transparent`,
    borderRight: `${Math.ceil(arrowWidth / 2) - 5}px solid transparent`,
    borderLeft: `${Math.ceil(arrowWidth / 2) + 5}px solid var(--active-color)`,
    zIndex: 1,
  };
};

const arrowBorderStyle = (height: number) => {
  return {
    content: '""',
    position: 'absolute',
    top: -2,
    right: `-${arrowWidth - 2}px`,
    borderTop: `${Math.ceil(height / 2) + 8}px solid transparent`,
    borderBottom: `${Math.ceil(height / 2) + 10}px solid transparent`,
    borderRight: `${Math.ceil(arrowWidth / 2) - 5}px solid transparent`,
    borderLeft: `${Math.ceil(arrowWidth / 2) + 5}px solid var(--border-color)`,
    zIndex: 1,
  };
};

export const BreadcrumbsItem = ({
  height = 50,
  bgColor = 'white',
  fontColor = 'gray.400',
  activeBgColor = 'brand.500',
  activeFontColor = 'white',
  borderColor = 'gray.300',
  isActive = false,
  isLast = false,
  icon,
  children
}: BreadcrumbsItemProps) => {
  const nextBgColor = isActive ? activeBgColor : bgColor;
  const nextFontColor = isActive ? activeFontColor : fontColor;
  return (
    <Box
      as={'li'}
      sx={{
        '--active-color': `colors.${nextBgColor}`,
        '--border-color': `colors.${borderColor}`,
      }}
      alia-current={isActive ? 'page' : undefined}
      fontSize={{ base: 'xs', md: 'md', lg: 'lg' }}
      pl={4}
      pr={1}
      display={'flex'}
      position={'relative'}
      bgColor={'var(--active-color)'}
      color={nextFontColor}
      fontWeight={'bold'}
      alignItems={'center'}
      justifyContent={'center'}
      w={'full'}
      h={`${height + 2}px`}
      _after={isLast ? {} : arrowStyle(height)}
      _before={isLast ? {} : arrowBorderStyle(height)}
    >
      {icon && (
        <Icon
          as={icon}
          display={{ base: 'none', md: 'block' }}
          fontSize={{ base: 'xs', md: 'xl', lg: '2xl' }}
          mr={3}
        />
      )}
      {children}
    </Box>
  );
};
