import {
  atom,
  selector,
  useRecoilCallback,
  useRecoilValue
} from 'recoil';
import {
  RecoilAtomKeys,
  RecoilSelectorKeys
} from '@/config/recoileKeys';
import { useCurrentCustomerQuery } from '@/graphql/generated/graphql';
import { CurrentCustomer } from '@/types/customer';
import { dayjs } from '@/utils/dayjs';

export type CurrentCustomerInfoState = {
  currentCustomer: CurrentCustomer | null;
  lastUpdated: dayjs.Dayjs | null;
};

export const defaultCurrentCustomerInfo: CurrentCustomerInfoState = {
  currentCustomer: null,
  lastUpdated: null,
};

export const currentCustomerInfoState = atom<CurrentCustomerInfoState>({
  key: RecoilAtomKeys.CURRENT_CUSTOMER_INFO,
  default: defaultCurrentCustomerInfo,
});

// ログインしているか
const isCustomerLoginSelector = selector({
  key: RecoilSelectorKeys.IS_CUSTOMER_LOGIN,
  get: ({ get }): boolean => {
    const { currentCustomer, lastUpdated } = get(currentCustomerInfoState);
    // NOTE: チェックして1分以上立っているかどうか
    return !!currentCustomer && !!(lastUpdated && lastUpdated.add(1, 'minutes').isAfter(dayjs()));
  }
});

export const recoilCurrentCustomerInfoSelectors = {
  useCurrentCustomer: () => useRecoilValue(currentCustomerInfoState).currentCustomer,
  useIsCheckedLogin: () => !!useRecoilValue(currentCustomerInfoState).lastUpdated,
  useIsCustomerLogin: () => useRecoilValue(isCustomerLoginSelector),
};

export const recoilCurrentCustomerInfoActions = {
  useSetCurrentCustomer: () => (
    useRecoilCallback(({ set }) => (
      currentCustomer: CurrentCustomer | null
    ) => {
      set(currentCustomerInfoState, (prev) => {
        return {
          ...prev,
          currentCustomer,
          lastUpdated: dayjs(),
        };
      });
    }, [])
  ),
};

/**
 * セッション情報からカスタマー情報をRecoilにセットします
 */
type UseSetCurrentCustomer = {
  refetchInterval?: number;
}
export const useSetCurrentCustomer = (
  {
    refetchInterval
  }: UseSetCurrentCustomer = {}) => {
  const setCurrentCustomer = recoilCurrentCustomerInfoActions.useSetCurrentCustomer();

  const { isLoading, refetch } = useCurrentCustomerQuery({}, {
    cacheTime: 0,
    refetchInterval,
    onSuccess: (data) => {
      const customer = data?.currentCustomer ?? null;
      setCurrentCustomer(customer);
    }
  });

  return {
    isLoading,
    refetch,
  };
};

