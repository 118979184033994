import {
  Button,
  ButtonProps,
  forwardRef
} from '@chakra-ui/react';

export interface SubmitButtonProps extends ButtonProps {
}

export const SubmitButton = forwardRef(({
  isDisabled,
  ...props
}: SubmitButtonProps, ref) => {
  return (
    <Button
      borderRadius="106px"
      colorScheme="brand"
      isDisabled={isDisabled}
      _hover={isDisabled ? {
        opacity: 'none'
      } : {
        opacity: 0.8
      }}
      ref={ref}
      {...props}
    />
  );
});
