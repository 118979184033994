import { useRouter } from 'next/router';
import {
  useEffect,
} from 'react';
import {
  useSetRecoilState
} from 'recoil';
import {
  getPageInfo
} from '@/config/pages';
import {
  recoilCurrentCustomerInfoSelectors,
} from '@/stores/currentCustomerInfo';
import { noLoginCustomerInfoState } from '@/stores/noLoginCustomerInfo';

type UseRequireCustomerLoginProps = {
  isRequired?: boolean;
};

export const useRequireLoginCustomer = (({
  isRequired = true
}: UseRequireCustomerLoginProps = {}) => {
  const isCheckedLogin = recoilCurrentCustomerInfoSelectors.useIsCheckedLogin();
  const isCustomerLogin = recoilCurrentCustomerInfoSelectors.useIsCustomerLogin();
  const setCustomerLogin = useSetRecoilState(noLoginCustomerInfoState);
  const router = useRouter();

  useEffect(() => {
    if (!isRequired || !isCheckedLogin) {
      return;
    }
    (async () => {
      if (!isCustomerLogin) {
        // ログインを求められたページのpathnameを保持する
        if (![
          getPageInfo('login', {}).href,
          getPageInfo('logout', {}).href,
        ].includes(router.pathname)) {
          setCustomerLogin((customerLogin) => {
            return {
              ...customerLogin,
              fromPathname: router.pathname
            };
          });
        }
        await router.replace(getPageInfo('login', {}).href);
      }
    })();
  }, [isCheckedLogin, isCustomerLogin, isRequired, router, setCustomerLogin]);

  return {
    isCheckedLogin,
    isCustomerLogin,
  };
});
