import {
  Icon,
  SearchIcon
} from '@chakra-ui/icons';
import {
  // eslint-disable-next-line no-restricted-imports
  Input,
  InputGroup,
  InputGroupProps,
  InputProps
} from '@chakra-ui/react';
import {
  SubmitButton,
  SubmitButtonProps
} from '@/components';

interface SearchInputProps extends InputGroupProps {
  onClick(): void;
  inputProps?: InputProps;
  buttonProps?: SubmitButtonProps;
  isDisabled?: boolean
}

export const SearchInput = ({ onClick, inputProps, buttonProps, isDisabled = false, ...props }: SearchInputProps) => {
  return (
    <InputGroup
      _focus={{ outline: 'none' }}
      {...props}
    >
      <Input
        borderTopLeftRadius="106px"
        borderBottomLeftRadius="106px"
        variant={'filled'}
        bg={'#e8e8e8'}
        {...inputProps}
      />
      <SubmitButton
        borderRadius={'none'}
        borderTopRightRadius="106px"
        borderBottomRightRadius="106px"
        onClick={onClick}
        isDisabled={isDisabled}
        px={10}
        {...buttonProps}
      >
        <Icon
          as={SearchIcon}
          textStyle={'h4'}
        />
      </SubmitButton>
    </InputGroup>
  );
};
