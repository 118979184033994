import {
  Box,
  BoxProps,

} from '@chakra-ui/react';
import { PcFooterItems } from './Pc/PcFooterItems';
import { SpFooterItems } from './Sp/SpFooterItems';
import {
  PcDisplayBlock,
  SpDisplayBlock
} from '@/components';

interface FooterProps extends BoxProps { }


export const Footer = ({
  ...props
}: FooterProps) => {
  return (
    <Box
      {...props}
    >
      <PcDisplayBlock>
        <PcFooterItems/>
      </PcDisplayBlock>

      <SpDisplayBlock>
        <SpFooterItems/>
      </SpDisplayBlock>
    </Box>
  );
};
