import {
  Button,
  ButtonProps,
  forwardRef
} from '@chakra-ui/react';
import {
  Size,
  sizes
} from '@/components/Button/size';

export interface LabelButtonProps extends ButtonProps {
  size?: Size;
  isActive?: boolean;
}

export const LabelButton = forwardRef(({
  size = 'medium',
  isActive = false,
  ...props
}: LabelButtonProps, ref) => {
  return (
    <Button
      borderRadius="106px"
      height={sizes[size].height}
      fontSize={sizes[size].fontSize}
      borderColor={isActive ? 'brand.500' : 'brandSecondary.500'}
      color={isActive ? 'brand.500' : 'brandSecondary.500'}
      colorScheme={'brandSecondary'}
      variant={'outline'}
      _hover={{ opacity: '0.8' }}
      ref={ref}
      {...props}
    />
  );
});
LabelButton.displayName = 'LabelButton';
