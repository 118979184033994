import {
  CloseIcon,
  HamburgerIcon,
  SearchIcon
} from '@chakra-ui/icons';
import {
  Flex,
  IconButton,
  useBoolean
} from '@chakra-ui/react';
import router from 'next/router';
import {
  memo,
  useCallback
} from 'react';
import { SpHamburgerMenu } from '@/components/Navbar/Sp/SpHamburgerMenu';
import { getPageInfo } from '@/config/pages';

type SpNavigationMenuProps = {
  onLogout(): void;
};

export const SpNavigationMenu = memo(({
  onLogout
}: SpNavigationMenuProps) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useBoolean(false);
  const handleCloseMobileMenu = useCallback(() => {
    setIsMobileMenuOpen.off();
  }, [setIsMobileMenuOpen]);
  return (
    <Flex
      display={{ base: 'flex', lg: 'none' }}
      flex={1}
      ml={-2}
      justify={'flex-end'}
    >
      <IconButton
        icon={
          <SearchIcon
            w={4}
            h={4}
          />
        }
        aria-label={'Search Link'}
        variant={'ghost'}
        onClick={async () => {
          await router.push(getPageInfo('studio', {}).href);
          handleCloseMobileMenu();
        }}
        _active={{ bg: 'white' }}
      />
      <IconButton
        onClick={isMobileMenuOpen ? setIsMobileMenuOpen.off : setIsMobileMenuOpen.on}
        icon={
          isMobileMenuOpen ? (
            <CloseIcon
              w={3}
              h={3}
            />
          ) : (
            <HamburgerIcon
              w={5}
              h={5}
            />
          )
        }
        variant={'ghost'}
        aria-label={'Toggle Navigation'}
      />
      <SpHamburgerMenu
        onLogout={onLogout}
        isOpen={isMobileMenuOpen}
        onClose={handleCloseMobileMenu}
      />
    </Flex>
  );
});
SpNavigationMenu.displayName = 'SpNavigationMenu';
