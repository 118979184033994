import {
  Box,
  BoxProps
} from '@chakra-ui/react';
import React from 'react';

type PaginationDotsProps = {}

const dotsProps: Partial<BoxProps> = {
  bg: 'gray.500',
  m: 1,
  w: 1,
  h: 1,
  mt: 5,
  borderRadius: 'lg',
  as: 'span'
};

const dots = [...Array(3)].map((_, i) => i);

export const PaginationDots = ({}: PaginationDotsProps) => {
  return (
    <>
      {dots.map((dot) =>
        <Box
          key={dot}
          {...dotsProps}
        />
      )}
    </>
  );
};
