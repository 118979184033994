import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { recoilTrackingParameterActions } from '@/stores/trackingParameter';

type URLParameterSetterProps = {};

export const URLParameterSetter = ({}: URLParameterSetterProps) => {
  const router = useRouter();
  const setParameter = recoilTrackingParameterActions.useSetParameter();

  useEffect(() => {
    setParameter(router.query);
  }, [router.query, setParameter]);

  return null;
};
