import {
  Box,
  BoxProps
} from '@chakra-ui/react';
import React from 'react';
import { IconType } from 'react-icons';
import { BreadcrumbsItem } from '@/components/StepBreadcrumbs/BreadcrumbsItem';

export type StepContent = {
  icon?: IconType;
  content: React.ReactNode;
}

// もっと良い書き方ああれば変えたい
type Color = BoxProps['bgColor'];
interface StepBreadcrumbsProps {
  height?: number;
  activeIndex?: number;
  bgColor?: Color;
  activeBgColor?: Color;
  borderColor?: Color;
  stepContents: StepContent[];
}

export const StepBreadcrumbs = ({
  height = 50,
  activeIndex,
  bgColor = 'white',
  activeBgColor = 'brand.500',
  borderColor = 'gray.300',
  stepContents
}: StepBreadcrumbsProps) => {
  return (
    <Box
      as={'nav'}
      w={'full'}
      aria-label="breadcrumbs"
    >
      <Box
        as={'ul'}
        w={'full'}
        sx={{
          '--border-color': `colors.${borderColor}`,
        }}
        display={'flex'}
        flexWrap={'nowrap'}
        justifyContent={'space-between'}
        borderColor={'var(--border-color)'}
        borderWidth={1}
        overflow={'hidden'}
      >
        {stepContents.map(({ icon, content }, index) => (
          <BreadcrumbsItem
            key={index}
            height={height}
            bgColor={bgColor}
            activeBgColor={activeBgColor}
            isActive={activeIndex === index}
            icon={icon}
          >
            {content}
          </BreadcrumbsItem>
        ))}
      </Box>
    </Box>
  );
};
