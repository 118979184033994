import { z } from 'zod';
import {
  BIRTHDAY,
  NAME_KANA,
  TEL_REG,
  MOBILE_NUMBER_REG,
} from '@/utils/regexp';

export const authenticationCodeInput = z.string()
  .regex(/^[0-9]{6}$/, { message: '6桁の数字を入力してください' })
  .min(1, { message: '入力してください' });

export type AuthenticationCodeInput = z.infer<typeof authenticationCodeInput>;

export const authenticationTokenInput = z.string().nullable();

export type AuthenticationTokenInput = z.infer<typeof authenticationTokenInput>;

export const emailInput = z.string()
  .min(1, { message: '入力してください' })
  .email({ message: 'メールアドレスの形式が正しくありません' });

//固定電話を含むバリデーション
export const telInput = z.string()
  .min(1, { message: '入力してください' })
  .regex(TEL_REG, { message: '半角数字のみで入力してください' })
  .min(10, { message: '10桁以上入力してください' }).max(12, { message: '12桁以下で入力してください' });

//携帯電話のみのバリデーション
export const mobileTelInput = z.string()
  .min(1, { message: '入力してください' })
  .regex(TEL_REG, { message: '半角数字のみで入力してください' })
  .length(11, { message: '11桁で入力してください' })
  .refine(value => MOBILE_NUMBER_REG.test(value), { message: '携帯電話番号の形式で入力してください' });

export const passwordInput = z.string().min(8, { message: '8文字以上で入力してください' });

export const birthdayInput = z.string()
  .min(1, { message: '入力してください' })
  .regex(BIRTHDAY, { message: 'YYYY-MM-DDの形式で入力してください（例: 1990-01-01）' });

export const sexInput = z.number().min(0, { message: '選択してください' }).max(1, { message: '選択してください' });

export const nameKanaInput = z.string()
  .regex(NAME_KANA, { message: '全角カタカナで入力してください' })
  .max(50, { message: '50文字以内で入力してください' });
