import { useCallback } from 'react';
import {
  CustomerLogoutMutation,
  useCustomerLogoutMutation
} from '@/graphql/generated/graphql';
import { recoilCurrentCustomerInfoActions } from '@/stores/currentCustomerInfo';

type UseCustomerLogoutProps = {
  // ログアウト成功時の処理
  onSuccessLogout?(): void;
}
export const useCustomerLogout = ({ onSuccessLogout }: UseCustomerLogoutProps = {}) => {
  const setCurrentCustomer = recoilCurrentCustomerInfoActions.useSetCurrentCustomer();

  const customerLogoutMutation = useCustomerLogoutMutation({
    onSuccess: ({ customerLogout: { result } }: CustomerLogoutMutation) => {
      if (result) {
        setCurrentCustomer(null);
        onSuccessLogout && onSuccessLogout();
      }
    }
  });

  const logout = useCallback(async () => {
    await customerLogoutMutation.mutate({});
  }, [customerLogoutMutation]);

  return {
    logout,
  };
};
