import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/modal';
import { ReactNode } from 'react';
import { SubmitButton } from '@/components';

type DialogProps = {
  isOpen: boolean;
  onClickCancel(): void;
  onClickOk?(): void;
  dialogHeading?: string;
  dialogBody?: ReactNode;
  cancelButtonText?: string;
  submitButtonText?: string;
}

export const Dialog = ({
  isOpen,
  onClickCancel,
  onClickOk,
  dialogHeading,
  dialogBody,
  cancelButtonText = 'キャンセル',
  submitButtonText = 'OK'
}: DialogProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClickCancel}
      isCentered={true}
      size={{base: 'sm', lg: '2xl'}}
    >
      <ModalOverlay/>
      <ModalContent>
        <ModalHeader>
          {dialogHeading}
        </ModalHeader>
        <ModalCloseButton/>
        <ModalBody>
          {dialogBody}
        </ModalBody>
        <ModalFooter>
          <SubmitButton
            size={'md'}
            variant={'outline'}
            borderColor={'brandSecondary.500'}
            color={'brandSecondary.500'}
            bgColor={'white'}
            mr={5}
            onClick={onClickCancel}
          >
            {cancelButtonText}
          </SubmitButton>
          {onClickOk &&
            <SubmitButton
              size={'md'}
              onClick={onClickOk}
            >
              {submitButtonText}
            </SubmitButton>
          }
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
