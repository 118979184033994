import {
  Box,
  BoxProps,
} from '@chakra-ui/react';
import Head from 'next/head';
import {
  memo,
} from 'react';
import {
  Breadcrumb,
  Navbar,
  navbarHeight
} from '@/components';
import { Clarity } from '@/components/Clarity/Clarity';
import { Footer } from '@/components/Footer/Footer';
import { PcFooterHeight } from '@/components/Footer/Pc/PcFooterItems';
import { SpFooterHeight } from '@/components/Footer/Sp/SpFooterItems';
import { GoogleTagManager } from '@/components/GoogleTagManager/GoogleTagManager';
import { URLParameterSetter } from '@/components/Layout/MainLayout/URLParameterSetter';
import { RequiredCustomerLogin } from '@/components/RequiredCustomerLogin/RequiredCustomerLogin';
import {
  ApplicationConfig,
  GoogleTagManagerId,
  SITE_DESCRIPTION,
  SITE_KEYWORDS,
  SITE_NAME,
  SITE_TITLE
} from '@/config/applicationConfig';
import {
  ReturnPageInfo,
} from '@/config/pages';
import {
  useSetCurrentCustomer,
} from '@/stores/currentCustomerInfo';

type MetaTagInfo = {
  title: string;
  description: string;
  keywords: string[];
  canonicalUrl?: string;
}

const defaultMetaTagInfo: MetaTagInfo = {
  title: SITE_TITLE,
  description: SITE_DESCRIPTION,
  keywords: SITE_KEYWORDS,
};

type Og = {
  'og:image': string;
  'og:title': string;
  'og:description': string;
  'twitter:card': string;
};

export const defaultOg: Og = {
  'og:image': `${ApplicationConfig.customerUrl}/images/ogp.jpg`,
  'og:title': SITE_TITLE,
  'og:description': SITE_DESCRIPTION,
  'twitter:card': 'summary_large_image'
};

interface MainLayoutProps extends BoxProps {
  metaTagInfo?: Partial<MetaTagInfo>;
  isShowNavbar?: boolean;
  isShowFooter?: boolean;
  isShowBreadcrumb?: boolean;
  isRequiredCustomerLogin?: boolean;
  breadcrumbLinks?: ReturnPageInfo[];
  jsonLd?: string;
  og?: Partial<Og>;
}

export const MainLayout = memo(({
  metaTagInfo,
  isShowNavbar = true,
  isShowFooter = true,
  isShowBreadcrumb = true,
  isRequiredCustomerLogin = false,
  breadcrumbLinks = [],
  children,
  jsonLd,
  og = {},
  ...props
}: MainLayoutProps) => {
  const nextMetaTagInfo: MetaTagInfo = {
    ...defaultMetaTagInfo,
    ...metaTagInfo,
  };

  const nextOg: Og = {
    ...defaultOg,
    ...Object.fromEntries(Object.entries(og).filter(([_k, v]) => v !== undefined))
  };

  useSetCurrentCustomer({ refetchInterval: 1000 * 60 * 3 });

  return (
    <>
      <Head>
        <title>{`${nextMetaTagInfo.title} | ${SITE_NAME}`}</title>
        <link
          rel="icon"
          type="image/ico"
          sizes="32x32"
          href={`${ApplicationConfig.customerUrl}/images/favicon.ico`}
        />
        <link
          rel="apple-touch-icon"
          type="image/ico"
          sizes="180x180"
          href={`${ApplicationConfig.customerUrl}/images/apple_touch_icon.png`}
        />
        <meta
          key="description"
          name="description"
          content={nextMetaTagInfo.description}
        />
        <meta
          key="keywords"
          name="keywords"
          content={nextMetaTagInfo.keywords.join(',')}
        />
        <meta
          key="viewport"
          name="viewport"
          content="width=device-width,initial-scale=1.0"
        />
        <meta
          key="og:image"
          property="og:image"
          content={nextOg['og:image']}
        />
        <meta
          key="og:title"
          property="og:title"
          content={`${nextOg['og:title']} | ${SITE_NAME}`}
        />
        <meta
          key="og:description"
          property="og:description"
          content={nextOg['og:description']}
        />
        <meta
          key="twitter:card"
          property="twitter:card"
          content={nextOg['twitter:card']}
        />
        <meta
          key="facebook-domain-verification"
          name="facebook-domain-verification"
          content="v3jnc855z9b23rpxe15weytwwj592q"
        />
        {nextMetaTagInfo.canonicalUrl && (
          <link
            key="canonical"
            rel="canonical"
            href={nextMetaTagInfo.canonicalUrl}
          />
        )}
        {jsonLd && (
          <script
            key="jsonld"
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: jsonLd }}
          />
        )}
      </Head>

      {ApplicationConfig.googleTagManagerId && (
        <GoogleTagManager
          googleTagManagerId={ApplicationConfig.googleTagManagerId as GoogleTagManagerId}
        />
      )}

      <URLParameterSetter/>

      <Navbar
        display={{ base: isShowNavbar ? 'block' : 'none' }}
      />
      <Box
        as={'main'}
        role={'main'}
        minH={{
          base: `calc(100vh - ${(navbarHeight + SpFooterHeight)}px)`,
          lg: `calc(100vh - ${(navbarHeight + PcFooterHeight)}px)`,
        }}
        maxW={'1280px'}
        margin={'0 auto'}
        {...props}
      >
        <Breadcrumb
          pageLinks={breadcrumbLinks}
          isShowBreadcrumb={isShowBreadcrumb}
        />
        <RequiredCustomerLogin
          isRequired={isRequiredCustomerLogin}
        >
          {children}
        </RequiredCustomerLogin>
      </Box>
      {isShowFooter && (
        <Footer/>
      )}

      {ApplicationConfig.clarityId && (
        <Clarity
          clarityId={ApplicationConfig.clarityId}
        />
      )}
    </>
  );
});
MainLayout.displayName = 'MainLayout';
