import { memo } from 'react';
import {
  Alert,
  AlertProps
} from '@/components/Alert/Alert/Alert';
import { ValidationError } from '@/graphql/errors';

type ValidationAlertProps = {
  title?: string;
  validationError: ValidationError | null;
} & Omit<AlertProps, 'title' | 'messages'>;

export const ValidationAlert = memo(({ validationError, title, status = 'error', ...props }: ValidationAlertProps) => {
  const fullMessages = validationError?.fullMessages || [];

  return (
    <>
      {validationError && (
        <Alert
          title={title || '入力にエラーがあります'}
          messages={fullMessages}
          status={status}
          {...props}
        />
      )}
    </>
  );
});
ValidationAlert.displayName = 'ValidationAlert';
