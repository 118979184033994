import {
  Flex,
  useBreakpointValue,
  Text,
  FlexProps
} from '@chakra-ui/react';
import { PaginationDots } from '@/components/Pagenation/PagenationDots';
import {
  PaginationItem
} from '@/components/Pagenation/PagenationItem';
import { KaminariPageInfoObject } from '@/graphql/generated/graphql';
import { buildUrlParamsString } from '@/utils/url';

type PaginationProps = {
  pageInfo: KaminariPageInfoObject;
  baseUrlPath?: string;
  params?: Record<string, any>;
  isLink?: boolean;
  onClick?(page: number): void;
} & Omit<FlexProps, 'onClick'>;

export const Pagination = ({
  pageInfo,
  baseUrlPath = '',
  params = {},
  isLink = true,
  onClick,
  ...rest
}: PaginationProps) => {
  const isPc = useBreakpointValue({ base: false, lg: true });
  const { currentPage, prevPage, nextPage, totalPages, totalCount } = pageInfo;
  const showPagesCount = isPc ? 10 : 4;
  const prevPagesCount = Math.floor(showPagesCount / 3);

  let firstPage = currentPage - prevPagesCount > 0 ? currentPage - prevPagesCount : 1;
  const firstPageCount = currentPage - firstPage;
  const needLastPageCount = showPagesCount - firstPageCount - 1;
  const lastPage = currentPage + needLastPageCount < totalPages ? currentPage + needLastPageCount : totalPages;
  const lastPageCount = lastPage - currentPage;
  if (lastPageCount < needLastPageCount) {
    firstPage = firstPage - (needLastPageCount - lastPageCount) > 0 ? firstPage - (needLastPageCount - lastPageCount) : 1;
  }

  return (
    <>
      {totalCount > 0 && (
        <Flex
          textAlign={'center'}
          verticalAlign={'center'}
          justifyContent={'center'}
          pb={2}
          position={'relative'}
          {...rest}
        >

          {currentPage !== 1 && (
            <PaginationItem
              href={baseUrlPath + buildUrlParamsString({ ...params, page: 1 })}
              isLink={isLink}
              onClick={() => onClick && onClick(1)}
            >
              &lt;&lt;
            </PaginationItem>
          )}

          {prevPage && (
            <PaginationItem
              href={baseUrlPath + buildUrlParamsString({ ...params, page: prevPage })}
              isLink={isLink}
              onClick={() => onClick && onClick(prevPage)}
            >
              &lt;
            </PaginationItem>
          )}

          {firstPage > 1 && (
            <PaginationDots/>
          )}

          {[...Array(lastPage - firstPage + 1)].map((_, index) => {
            const page = index + firstPage;
            return (
              <PaginationItem
                key={page}
                href={baseUrlPath + buildUrlParamsString({ ...params, page })}
                isLink={isLink}
                active={currentPage === page}
                onClick={() => onClick && onClick(page)}
              >
                <Text fontSize={page.toString().length < 5 ? '12px' : '10px'}>{page}</Text>
              </PaginationItem>
            );
          })}

          {lastPage < totalPages && (
            <PaginationDots/>
          )}

          {nextPage && (
            <PaginationItem
              href={baseUrlPath + buildUrlParamsString({ ...params, page: nextPage })}
              isLink={isLink}
              onClick={() => onClick && onClick(nextPage)}
            >
              <Text pb={'2px'}>&gt;</Text>
            </PaginationItem>
          )}

          {currentPage !== totalPages && (
            <PaginationItem
              href={baseUrlPath + buildUrlParamsString({ ...params, page: totalPages })}
              isLink={isLink}
              onClick={() => onClick && onClick(totalPages)}
            >
              <Text pb={'2px'}>&gt;&gt;</Text>
            </PaginationItem>
          )}
        </Flex>
      )}
    </>
  );
};
