export enum RecoilAtomKeys {
  BOOKING_INPUT = 'BOOKING_INPUT',
  CURRENT_CUSTOMER_INFO = 'CURRENT_CUSTOMER_INFO',
  CUSTOMER_REGISTER = 'CUSTOMER_REGISTER',
  CUSTOMER_EDIT_TEL = 'CUSTOMER_EDIT_TEL',
  CUSTOMER_EDIT_EMAIL = 'CUSTOMER_EDIT_EMAIL',
  SHOP_SEARCH = 'SHOP_SEARCH',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  TRACKING_PARAMETER = 'TRACKING_PARAMETER',
}

export enum RecoilSelectorKeys {
  // bookingInput
  BOOKING_PAGE_VARIABLES = 'BOOKING_PAGE_VARIABLES',
  PM_COURSE_IDS = 'PM_COURSE_IDS',
  PM_COURSE_OPTION_IDS = 'PM_COURSE_OPTION_IDS',
  COURSE_CATEGORIES = 'COURSE_CATEGORIES',
  COURSE = 'COURSE',
  COURSE_OPTIONS = 'COURSE_OPTIONS',
  COURSE_COUNT = 'COURSE_COUNT',
  COURSE_OPTION_COUNT = 'COURSE_OPTION_COUNT',
  TOTAL_MINUTES = 'TOTAL_MINUTES',
  TOTAL_PRICE = 'TOTAL_PRICE',
  FORMATTED_BOOKING_AT = 'FORMATTED_BOOKING_AT',
  STAFF_OPTIONS = 'STAFF_OPTIONS',
  STAFF_OPTION = 'STAFF_OPTION',
  COURSE_PARAMS = 'COURSE_PARAMS',
  TIME_TABLES_BY_SHOP_SHOP_PARAMS = 'TIME_TABLES_BY_SHOP_SHOP_PARAMS',
  BOOKING_CONFIRM_PARAMS = 'BOOKING_CONFIRM_PARAMS',
  BOOKING_PARAMS = 'BOOKING_PARAMS',
  IS_BOOKABLE = 'IS_BOOKABLE',
  CAN_MOVE_STEP2 = 'CAN_MOVE_STEP2',
  CAN_MOVE_STEP3 = 'CAN_MOVE_STEP3',
  SHOULD_REDIRECT_TO_STEP3 = 'SHOULD_REDIRECT_TO_STEP3',

  // currentCustomerInfo
  IS_CUSTOMER_LOGIN = 'IS_CUSTOMER_LOGIN ',

  // trackingParameter
  UTM_TRACKING_PARAMETER = 'UTM_TRACKING_PARAMETER',
}
