import {
  // eslint-disable-next-line no-restricted-imports
  RequiredIndicator as ChakraRequiredIndicator,
  RequiredIndicatorProps as ChakraRequiredIndicatorProps
} from '@chakra-ui/react';

interface RequiredIndicatorProps extends ChakraRequiredIndicatorProps {
}

export const RequiredIndicator = (props: RequiredIndicatorProps) => {
  return (
    <ChakraRequiredIndicator {...props}>
      *
    </ChakraRequiredIndicator>
  );
};
