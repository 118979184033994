export const SITE_NAME = 'マッサージ・整体ファンにも大人気のRe.Ra.Ku グループ(リラクグループ)';
export const SITE_TITLE = 'Re.Ra.Ku グループ';
export const SITE_DESCRIPTION = '全国に約300店舗のリラクゼーションサロンを展開。肩甲骨と骨盤に着目したボディケアでお客様の疲労を撃退し、マッサージ好きなあなたの健康で快適な生活をサポートします。';
export const SITE_KEYWORDS = [
  'リラクゼーション',
  'リラク',
  'Re.Ra.Ku',
  'マッサージ',
  '肩甲骨',
  'ストレッチ',
];

export type GoogleTagManagerId = `GTM-${string}`;
type ApplicationConfig = {
  nodeEnv: 'development' | 'production';
  appEnv: 'local' | 'development' | 'production';
  graphqlUrl: string;
  customerUrl: string;
  apiKeyByClient: string;
  apiKeyByServer: string;
  googleMapsApiKey: string;
  googleTagManagerId: GoogleTagManagerId | string;
  clarityId: string;
  sitemapUrl: string;
  pmShopIdForPay: string;
  rerakuPayUrl: string;
  basicAuthUsername: string;
  basicAuthPassword: string;
}

export const ApplicationConfig: ApplicationConfig = {
  nodeEnv: process.env.NEXT_PUBLIC_NODE_ENV as ApplicationConfig['nodeEnv'] || 'development',
  appEnv: process.env.NEXT_PUBLIC_APP_ENV as ApplicationConfig['appEnv'] || 'local',
  graphqlUrl: `${(process.env.NEXT_PUBLIC_BACKEND_URL || '')}${(process.env.NEXT_PUBLIC_GRAPHQL_PATH || '')}`,
  customerUrl: process.env.NEXT_PUBLIC_CUSTOMER_URL || '',
  apiKeyByClient: process.env.NEXT_PUBLIC_BACKEND_API_KEY || '',
  apiKeyByServer: process.env.BACKEND_API_KEY || '',
  googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY || '',
  googleTagManagerId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID || '',
  clarityId: process.env.NEXT_PUBLIC_CLARITY_ID || '',
  sitemapUrl: process.env.NEXT_PUBLIC_SITEMAP_URL || '',
  pmShopIdForPay: process.env.NEXT_PUBLIC_PM_SHOP_ID_FOR_PAY || '',
  rerakuPayUrl: process.env.NEXT_PUBLIC_RERAKU_PAY_URL || '',
  basicAuthUsername: process.env.BASIC_AUTH_USERNAME || 'username',
  basicAuthPassword: process.env.BASIC_AUTH_PASSWORD || 'password',
};
