import {
  forwardRef,
  // eslint-disable-next-line no-restricted-imports
  Input as ChakraInput,
  InputProps as ChakraInputProps,
} from '@chakra-ui/react';

export interface InputProps extends ChakraInputProps {
}

export const Input = forwardRef((
  { ...restProps }: InputProps, ref) => {
  return (
    <ChakraInput
      borderColor={'gray.300'}
      ref={ref}
      {...restProps}
    />
  );
});
