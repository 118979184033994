import {
  forwardRef,
  Text,
  TextProps
} from '@chakra-ui/react';
import React, { MouseEventHandler } from 'react';

export interface TextLinkProps extends TextProps {
  children: React.ReactNode;
  href?: string;
  onClick?: MouseEventHandler;
}

export const TextLink = forwardRef(({
  children,
  href,
  onClick,
  ...textProps
}: TextLinkProps, ref) => {
  return (
    <Text
      as={href ? 'a' : undefined}
      href={href}
      display={'inline'}
      color={'brand.500'}
      fontWeight={'bold'}
      _hover={{ textDecoration: 'none' }}
      onClick={onClick}
      cursor={(href || onClick) ? 'pointer' : undefined}
      ref={ref}
      {...textProps}
    >
      {children}
    </Text>
  );
});
