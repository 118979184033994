import {
  AlertDescription,
  AlertIcon,
  AlertStatus,
  AlertTitle,
} from '@chakra-ui/alert';
import {
  Alert as ChakraAlert,
  AlertProps as ChakraAlertProps,
  Box,
  CloseButton,
} from '@chakra-ui/react';
import {
  memo,
  useCallback,
  useState
} from 'react';

export type AlertProps = {
  title: string;
  messages: string[];
  status?: AlertStatus;
  closeable?: boolean;
} & ChakraAlertProps;

export const Alert = memo(({ title, messages, status, closeable, ...props }: AlertProps) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <>
      {isOpen && (
        <ChakraAlert
          status={status}
          {...props}
        >
          <AlertIcon/>
          <Box w={'100%'}>
            {title && (
              <AlertTitle>{title}</AlertTitle>
            )}
            <AlertDescription>
              {messages.map((message, index) => (
                <p key={index}>{message}</p>
              ))}
            </AlertDescription>
          </Box>
          {closeable && (
            <CloseButton
              alignSelf="flex-start"
              position="relative"
              right={-1}
              top={-1}
              onClick={handleClose}
            />
          )}
        </ChakraAlert>
      )}
    </>
  );
});
Alert.displayName = 'Alert';
