import https from 'https';
import axios from 'axios';
import { withHttpErrorFromBackend } from '@/api/withHttpError';
import { ApplicationConfig } from '@/config/applicationConfig';
import {
  findGraphQLValidationError,
  GraphQLError,
  GraphQLErrorResponseError,
  GraphQLValidationError,
  isGraphQLErrorResponseError
} from '@/graphql/errors';

/**
 * graphQLへアクセスするためのfetcher
 * ctxパラメータを渡すと、サーバーサイド側からの利用を想定した動きとなる
 * @param query
 * @param variables
 * @param options
 */
export const fetcher = <TData, TVariables>(
  query: string,
  variables?: TVariables,
  options?: RequestInit['headers'],
): (() => Promise<TData>) => {
  return async () => {
    let httpsAgent: https.Agent | undefined = undefined;
    if (ApplicationConfig.appEnv === 'local' && https.Agent) {
      httpsAgent = new https.Agent({
        rejectUnauthorized: false,
      });
    }
    options = {
      ...options,
      'X-API-KEY': ApplicationConfig.apiKeyByClient,
    };
    const res = await withHttpErrorFromBackend<{ data: TData } | GraphQLErrorResponseError>(async () => {
      return await axios.post<{ data: TData } | GraphQLErrorResponseError>(
        ApplicationConfig.graphqlUrl,
        JSON.stringify({
          query,
          variables,
        }),
        {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': ApplicationConfig.customerUrl || '',
            'X-USE-API': 'reraku-graphql-api',
            ...((options || {}) as { [key: string]: string }),
          },
          withCredentials: true,
          httpsAgent,
        }
      );
    });
    const { data } = res;

    if (isGraphQLErrorResponseError(data)) {
      const validationError = findGraphQLValidationError(data.errors);
      if (validationError) {
        throw new GraphQLValidationError(validationError.extensions);
      }
      const message = data.errors[0].message;
      throw new GraphQLError(message);
    }

    return data.data;
  };
};
