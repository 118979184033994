import {
  // eslint-disable-next-line no-restricted-imports
  Radio as ChakraRadio,
  RadioProps as ChakraRadioProps,
  forwardRef
} from '@chakra-ui/react';

interface RadioProps extends ChakraRadioProps {

}

export const Radio = forwardRef((props: RadioProps, ref) => {
  return (
    <ChakraRadio
      borderColor={'gray.300'}
      colorScheme={'brand'}
      ref={ref}
      {...props}
    />
  );
});
