import {
  ArrowBackIcon,
  ArrowForwardIcon,
  Icon,
  IconProps,
} from '@chakra-ui/icons';
import {
  Box,
  Button,
  ButtonProps,
  forwardRef
} from '@chakra-ui/react';

interface NextButtonProps extends ButtonProps {
  isBack?: boolean;
  iconProps?: IconProps;
}

export const NextButton = forwardRef(({
  isBack = false,
  colorScheme = 'brand',
  children,
  iconProps = {},
  ...props
}: NextButtonProps, ref) => {
  return (
    <Button
      borderRadius="106px"
      color={colorScheme === 'brand' ? 'brand.500' : undefined}
      colorScheme={colorScheme}
      variant={'outline'}
      iconSpacing={10}
      ref={ref}
      {...props}
    >
      <Box
        display={'flex'}
        w={'full'}
        h={'full'}
        alignItems={'stretch'}
      >
        {isBack && (
          <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            w={'15px'}
            h={'full'}
          >
            <Icon
              as={ArrowBackIcon}
              {...iconProps}
            />
          </Box>
        )}
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          w={'full'}
          h={'full'}
        >
          {children}
        </Box>
        {!isBack && (
          <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            w={'15px'}
            h={'full'}
          >
            <Icon
              as={ArrowForwardIcon}
              {...iconProps}
            />
          </Box>
        )}
      </Box>
    </Button>
  );
});
NextButton.displayName = 'NextButton';
