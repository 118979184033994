import {
  atom,
  useRecoilCallback,
  useRecoilValue,
} from 'recoil';

type NoLoginCustomerInfoState = {
  // ログイン前のURL
  fromPathname: string | null;
}

export const defaultNoLoginCustomerInfo: NoLoginCustomerInfoState = {
  fromPathname: null,
};

export const noLoginCustomerInfoState = atom<NoLoginCustomerInfoState>({
  key: 'noLoginCustomerInfoAtom',
  default: defaultNoLoginCustomerInfo,
});

type NoLoginCustomerInfoSelectors = {
  useFromPathname: () => string | null;
}

export const noLoginCustomerInfoSelectors: NoLoginCustomerInfoSelectors = {
  useFromPathname: () => useRecoilValue(noLoginCustomerInfoState).fromPathname,
};

type NoLoginCustomerInfoActions = {
  useSetFromPathname: () => (pathname: string | null) => void;
  useResetFromPathname: () => () => void;
}

export const noLoginCustomerInfoActions: NoLoginCustomerInfoActions = {
  useSetFromPathname: () => (
    useRecoilCallback(({ set }) => (pathname: string | null) => {
      set(noLoginCustomerInfoState, (prev) => ({
        ...prev,
        fromPathname: pathname,
      }));
    })
  ),

  useResetFromPathname: () => (
    useRecoilCallback(({ set }) => () => {
      set(noLoginCustomerInfoState, (prev) => ({
        ...prev,
        fromPathname: null,
      }));
    })
  )
};
